import React from "react"
import Contact from "./../components/Contact" // Remplacez par le chemin correct vers votre composant Contact
import "./Contacteznous.css"
import ReseauxSociaux from "../components/ReseauxSociaux"
import Footer from "./../components/Footer/Footer"
function Contacteznous() {
	return (
		<div className="contactez-nous-container">
			<h2>Contactez-nous !</h2>

			<Contact />
			<ReseauxSociaux />
			<p />
		</div>
	)
}

export default Contacteznous
