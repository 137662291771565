import React from "react"
import "./PdfDownloadButton.css" // Assurez-vous que le fichier CSS existe et est correctement lié
import pdfFile from "../assets/PlaquetteMaluko.pdf" // Mettez à jour le chemin d'accès vers le fichier PDF
import downloadIcon from "../assets/cloudpdf.png" // Mettez à jour le chemin d'accès vers l'icône
import buttonImage from "../assets/5.png" // Remplacez avec le chemin d'accès vers votre image de bouton

const PdfDownloadButton = () => {
  return (
    <div className="pdf-download-container">
      <a href={pdfFile} download="Plaquette.pdf">
        <button type="button" className="pdf-download-button">
          <img src={buttonImage} alt="Plaquette PDF" />{" "}
          {/* Image ajoutée dans le bouton */}
        </button>
      </a>
    </div>
  )
}

export default PdfDownloadButton
