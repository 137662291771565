import React from "react"
import "./ReseauxSociaux.css"
import InstagramIcon from "./../assets/insta.png" // Assurez-vous que le chemin est correct

const ReseauxSociaux = () => {
  return (
    <div className="container">
      {" "}
      {/* Conteneur englobant ajouté */}
      <div className="social-media-icons">
        <a
          href="https://instagram.com/maluko.fr?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
          className="icon instagram"
        >
          <img src={InstagramIcon} alt="Instagram" />
        </a>
        <h4>Suivez Maluko sur Instagram</h4>{" "}
        {/* Utiliser un élément h2 pour le texte */}
        {/* Ajoutez d'autres réseaux sociaux si nécessaire */}
      </div>
      
    </div>
    
  )
}

export default ReseauxSociaux
