import React from "react"
import Picture from "../components/picture"
import "./Galerie.css"
import Grid from "../components/Grid/Grid"
import Footer from "./../components/Footer/Footer"
const importAll = (r) => {
	return r.keys().map(r)
}

const images = importAll(
	require.context("../components/dossier/galerie2", false, /\.(png|jpe?g|svg|PNG|JPE?G|SVG|JPG|jpg)$/i)
)

const Galerie = () => {
	// Extraire et trier les images en supposant que les noms de fichiers sont des nombres
	const sortedImages = images
		.map((imagePath) => {
			const name = imagePath.split("/").pop() // Extraire le nom de fichier
			const numberPart = parseInt(name, 10) // Extraire la partie numérique du nom de fichier
			return { path: imagePath, number: numberPart }
		})
		.sort((a, b) => a.number - b.number) // Trier par numéro
		.map((image) => image.path) // Récupérer les chemins triés

	// Créer les objets d'images avec le chemin et la description
	const imageObjects = sortedImages.map((src, index) => ({
		src,
		alt: `Description ${index + 1}`,
	}))

	return (
		<div className="galerie-page">
			<h2>Galerie Maluko !</h2>
			{/* <Grid images={imageObjects} /> */}
			<Picture images={imageObjects} />
		</div>
	)
}

export default Galerie
