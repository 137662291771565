import React, { useState, useEffect } from "react"
import "./Quisommesnous.css" // Ensure this CSS file includes the new styles
import "./../components/AnimatedListItem"
import teamPhoto from "../assets/1-min.jpg"
import actionPhoto from "../assets/P1000676.JPG"
import tarifs from "../assets/tarifs.jpg"
import quantite from "../assets/quantite.jpg"
import delais from "../assets/delais.JPG"
import icon2 from "../components/dossier/icon/icon2.png"
import icon3 from "../components/dossier/icon/icon3.png"
import icon4 from "../components/dossier/icon/icon4.png"
import icon5 from "../components/dossier/icon/icon5.png"
import icon6 from "../components/dossier/icon/icon6.png"
import icon7 from "../components/dossier/icon/icon7.png"
import icon8 from "../components/dossier/icon/icon8.png"
import AnimatedListItem from "../components/AnimatedListItem"
import AnimatedWords from "../components/AnimatedWords"
import PdfDownloadButton from "../components/PdfDownloadButton"
import Footer from "./../components/Footer/Footer"
import Contact from "../components/Contact"
function Quisommesnous() {
	// Content array for the items
	const content = [
		{ icon: icon2, text: "Entreprise Française" },
		{ icon: icon3, text: "Fait main" },
		{ icon: icon5, text: "Entreprise soucieuse de l'environnement" },
		{ icon: icon4, text: "Haute qualité de produits" },
		{
			icon: icon6,
			text: [
				"Accompagnement de A à Z tout au long du projet",
				"    - Création du Design",
				"    - Conseil individualisé",
				"    - Personnalisation",
			],
		},

		{ icon: icon7, text: "Savoir-faire, expertise, créativité" },
		{ icon: icon8, text: "100% Personnalisation" },
	]
	return (
		<div className="about-us">
			<h2>Qui sommes-nous ?</h2>
			{/* <div className="grid-container"> */}
			{/* Other grid items */}
			{/* <AnimatedWords word1="Personnalisation de T-Shirts" font1="'Dancing Script', cursive" /> */}

			{/* Other grid items */}
			{/* </div> */}
			<section className="about-details">
				<div className="intro-text">
					<h4>Introduction</h4>
					<p>
						Lola, fondatrice de Maluko, passionnée et débordante de créativité, est spécialiste en matière
						de flocage. Avec un œil pour le détail et un engagement pour la qualité, elle transforme chaque
						projet en une création 100% personnalisée.
					</p>
					<p>
						Conseils, accompagnement dédié caractérisent ce que l’entreprise Maluko vous propose. Donnez vie
						à vos idées et appréciez la valeur d’un travail fait avec cœur et expertise.
					</p>
				</div>

				<img src={teamPhoto} alt="Team" className="team-photo fadeInUp" />
			</section>
			<section className="about-intro">
				<div className="intro-text">
					<h4>Que propose Maluko ?</h4>
					<p>
						Maluko est une entreprise spécialisée dans le conseil et la personnalisation de textiles.
						L’entreprise Maluko met un point d’honneur à l’accompagnement client, les projets seront suivis
						de A à Z par l’entreprise.
					</p>
					<p>
						La technique du flocage que l’entreprise utilise est intéressante pour plusieurs raisons : elle
						offre un résultat visuel éclatant grâce à des couleurs qui ressortent et qui sont visibles même
						de loin. Le flocage permet d'obtenir des détails nets et précis, ce qui en fait un choix idéal
						pour personnaliser des textiles, des vêtements, des sacs... De plus, il offre une durabilité aux
						produits, résistant aux lavages répétés et à l'usure quotidienne.
					</p>
					<p>
						Également spécialisée dans le graphisme, Maluko propose aussi de repenser vos logos, ainsi que
						de concevoir des visuels.
					</p>
				</div>
				<div className="intro-image">
					<img src={actionPhoto} alt="Action" className="action-photo fadeInUp" />
				</div>
			</section>
			<div className="animated-list-container">
				{content.map((item, index) => (
					<AnimatedListItem key={index} icon={item.icon} text={item.text} />
				))}
			</div>
			<section className="about-details">
				<div className="intro-text">
					<h4>Les quantités</h4>
					<p>
						Maluko propose de la personnalisation à l’unité mais aussi en grandes quantités. L’entreprise se
						spécialise dans l'équipement des sportifs en collaboration avec différentes associations
						sportives. Maluko a déjà habillé 780 adhérents du club de Villemomble Gymnastique ou encore 350
						Gymnastes du club de Montreuil.
					</p>
				</div>

				<img src={quantite} alt="quantite" className="quantite fadeInUp" />
			</section>
			<section className="about-intro">
				<div className="intro-text">
					<h4>Les tarifs</h4>
					<p>
						Les tarifs peuvent varier en fonction du projet, du nombre de couleurs, du nombre de quantités
						demandées et de la qualité de la matière première choisie. N’hésitez pas à nous faire une
						demande de devis.
					</p>
				</div>
				<div className="intro-image">
					<img src={tarifs} alt="tarifs" className="tarifs fadeInUp" />
				</div>
			</section>
			<section className="about-details">
				<div className="intro-text">
					<h4>Les délais</h4>
					<p>
						Les délais varient en fonction des projets et des quantités. Le délai minimum est de 2 semaines.
						N’hésitez pas à nous faire une demande de devis.
					</p>
				</div>

				<img src={delais} alt="Team" className="team-photo fadeInUp" />
			</section>
			<h2>Télécharger la Plaquette ! </h2>
			<div className="main-container">
				<div className="pdf-download-container">
					<PdfDownloadButton buttonText="Plaquette pdf" />
				</div>
				{/* Autres éléments de votre page */}
			</div>
			<h2>Contactez-nous !</h2>
			<Contact />
			{/* ... more content and sections */}
			{/* <Footer />{" "} */}
			{/* Le footer est placé ici, à l'extérieur de la div .about-us */}
		</div>
	)
}

export default Quisommesnous
