import React, { useRef, useState } from "react"
import { CiMail } from "react-icons/ci"
import emailjs from "emailjs-com"
import "./Contact.css"

const Contact = () => {
	const form = useRef()
	const [submitted, setSubmitted] = useState(false)
	const [success, setSuccess] = useState(false)
	const [formData, setFormData] = useState({
		user_prenom: "",
		user_nom: "",
		user_tel: "",
		user_email: "",
		user_demande: "",
		message: "",
		user_autre_demande: "",
	})

	const sendEmail = (e) => {
		e.preventDefault()

		emailjs.sendForm("service_fwkznc8", "template_idmcphg", form.current, "lfz-yKrbbdJd2wltC").then(
			(result) => {
				console.log(result.text)
				setSubmitted(true)
				setSuccess(true)
			},
			(error) => {
				console.log(error.text)
				setSubmitted(true)
				setSuccess(false)
			}
		)
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		setFormData({ ...formData, [name]: value })
	}

	return (
		<section id="contact">
			<div className="contact__layout">
				{" "}
				{/* This is the new parent div */}
				<div className="contact__options">
					{" "}
					{/* This will be the first column */}
					<article className="contact__option">
						<CiMail className="contact__option-icon" />
						<h4>Email</h4>
						<h5>contact@maluko.fr</h5>
					</article>
				</div>
				<div className="contact__container">
					<form ref={form} onSubmit={sendEmail}>
						{/* Form inputs and submit button */}
						<div className="name-fields">
							<input
								type="text"
								name="user_prenom"
								placeholder="Prénom"
								required
								value={formData.user_prenom}
								onChange={handleChange}
							/>
							<input
								type="text"
								name="user_nom"
								placeholder="Nom"
								required
								value={formData.user_nom}
								onChange={handleChange}
							/>
						</div>
						<input
							type="text"
							name="user_tel"
							placeholder="Numéro de téléphone"
							value={formData.user_tel}
							onChange={handleChange}
						/>
						<input
							type="email"
							name="user_email"
							placeholder="Email"
							required
							value={formData.user_email}
							onChange={handleChange}
						/>
						<select name="user_demande" required value={formData.user_demande} onChange={handleChange}>
							<option value="">-- Sélectionnez une demande --</option>
							<option value="Demande de renseignements">Demande de renseignements</option>
							<option value="Demande de devis">Demande de devis</option>
							<option value="Suivi de commande">Suivi de commande</option>
							<option value="Envoi de photos">Envoi de photos</option>
							<option value="Autres">Autres</option>
						</select>
						{formData.user_demande === "Autres" && (
							<input
								type="text"
								name="user_autre_demande"
								placeholder="Précisez votre demande"
								value={formData.user_autre_demande || ""}
								onChange={handleChange}
							/>
						)}
						<textarea
							name="message"
							rows="7"
							placeholder="Votre message ici"
							required
							value={formData.message}
							onChange={handleChange}
						></textarea>
						<button type="submit" className="btn btn-primary">
							Soumettre
						</button>
					</form>
				</div>
			</div>
			{submitted && success && <p className="form-success">Votre message a été envoyé avec succès !</p>}
			{submitted && !success && <p className="form-error">Un problème est survenu. Veuillez réessayer.</p>}
		</section>
	)
}

export default Contact
