// src/components/AnimatedListItem.js

import React, { useRef, useEffect } from "react"
import "./AnimatedListItem.css" // Make sure to create this CSS file

function AnimatedListItem({ icon, text }) {
  const itemRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("comeIn")
          }
        })
      },
      { threshold: 0.5 }
    )

    if (itemRef.current) {
      observer.observe(itemRef.current)
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current)
      }
    }
  }, [])

  return (
    <div ref={itemRef} className="animated-list-item">
      <div className="icon">
        <img src={icon} alt="Icon" />
      </div>
      {Array.isArray(text) ? (
        <div>
          {text.map((line, index) => (
            <h3 key={index} className={index === 0 ? "" : "bullet-item"}>
              {line.replace(/^\s*-\s*/, "") /* Remove leading dash */}
            </h3>
          ))}
        </div>
      ) : (
        <h3>{text}</h3>
      )}
    </div>
  )
}

export default AnimatedListItem
