import React from "react"
import { Link } from "react-router-dom"
import "./Footer.css"

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-top">
        <div className="footer-section">
          <h2>Contactez nous !</h2>
          <p>
            <Link to="/contact">contact@maluko.fr</Link>
          </p>
          <p>
            <Link to="/formulaire">formulaire de contact</Link>
          </p>
          <p>
            <Link to="/">maluko.fr</Link>
          </p>
        </div>
        <div className="footer-section">
          <h2>Qui sommes nous ?</h2>
          <p>
            <Link to="/tarifs">Les tarifs</Link>
          </p>
          <p>
            <Link to="/livraison">Les délais</Link>
          </p>
          <p>
            <Link to="/sav">Le SAV</Link>
          </p>
        </div>
        <div className="footer-section">
          <h2>Nos services !</h2>
          <p>
            <Link to="/conseil">Conseil</Link>
          </p>
          <p>
            <Link to="/accompagnement">Accompagnement</Link>
          </p>
          <p>
            <Link to="/personalisation">Personnalisation textile</Link>
          </p>
          <p>
            <Link to="/newsletter">S'inscrire à la Newsletter</Link>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <Link to="/confidentialite">Politique de Confidentialité</Link>
        <Link to="/mentions">Mentions Légales</Link>
        <Link to="/conditions-utilisation">
          Conditions Générales d'Utilisation
        </Link>
        <Link to="/privacyPolicy">Conditions générales de Vente</Link>
      </div>
    </footer>
  )
}

export default Footer
