import React from "react"
import "./Accueil.css" // Assurez-vous que ce fichier CSS est créé
import { Link } from "react-router-dom" // Importer Link de react-router-dom
import logo from "./../assets/logo5.png" // Mettre à jour le chemin selon votre logo
import InfiniteScroll from "../components/InfiniteScroll/InfiniteScroll"
import Footer from "./../components/Footer/Footer" // Assurez-vous d'importer le composant Footer

function Accueil() {
	return (
		<div className="home">
			<header className="hero">
				<img src={logo} alt="Maluko T-Shirts" className="logo" />
				<h1>Bienvenue sur Maluko !</h1>
				<p>Faites concevoir le t-shirt idéal pour votre personnalité !</p>
				<Link to="/quiSommesNous">
					{/* Mettre à jour le chemin selon votre route */}
					<button className="shop-now-btn">Qui sommes-nous ?</button>
				</Link>
				<Link to="/contacteznous">
					{/* Mettre à jour le chemin selon votre route */}
					<button className="shop-now-btn">Contactez-nous ?</button>
				</Link>

				<h1>Vite, commandez vos t-shirts Maluko !</h1>
			</header>
			{/* <InfiniteScroll /> */}
			{/* Ajoutez d'autres sections si nécessaire */}
		</div>
	)
}

export default Accueil
