import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import logo from "../assets/Maluko_Blanc_Sans_fond.png"
import "./Navbar.css"
// Supprimez l'importation de Blur si vous n'utilisez pas de composant spécifique pour le flou

function Navbar() {
	const [isScrolled, setIsScrolled] = useState(false)
	const [isMenuActive, setIsMenuActive] = useState(false)
	const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 50) {
				setIsScrolled(true)
			} else {
				setIsScrolled(false)
			}
		}

		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	const handleHamburgerClick = () => {
		setIsMenuActive(!isMenuActive)
		setIsBackgroundBlurred(!isMenuActive)
	}

	const handleLinkClick = () => {
		setIsMenuActive(false)
		setIsBackgroundBlurred(false)
	}

	return (
		<>
			{isBackgroundBlurred && <div className="blur-background"></div>}
			<nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
				<div className="navbar-logo">
					<Link to="/">
						<img src={logo} alt="Logo" />
					</Link>
				</div>
				<div className={`navbar-hamburger ${isMenuActive ? "active" : ""}`} onClick={handleHamburgerClick}>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<ul className={`navbar-menu ${isMenuActive ? "active" : ""}`}>
					<li onClick={handleLinkClick}>
						<Link to="/">Accueil</Link>
					</li>
					<li onClick={handleLinkClick}>
						<Link to="/galerie">Galerie</Link>
					</li>
					<li onClick={handleLinkClick}>
						<Link to="/contacteznous">Contactez-nous !</Link>
					</li>
					<li onClick={handleLinkClick}>
						<Link to="/quiSommesNous">Qui sommes-nous ?</Link>
					</li>

					{/* <li onClick={handleLinkClick}>
						<Link to="/Stores">SAV</Link>
					</li> */}
					<li onClick={handleLinkClick}>
						<Link to="/contacteznous">SAV</Link>
					</li>
				</ul>
			</nav>
		</>
	)
}

export default Navbar
