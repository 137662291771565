import React, { useState } from "react"
import "./picture.css"

const Picture = ({ images }) => {
	const [selectedImage, setSelectedImage] = useState(null)

	const handleImageClick = (image) => {
		setSelectedImage(image)
	}

	// Déterminer la classe CSS en fonction de la position de l'image
	const determinePhotoClass = (index) => {
		switch (
			index % 20 // 20 cas différents
		) {
			case 0:
				return "photo-medium"
			// case 1:
			//   return "photo-wide"
			// case 2:
			//   return "photo-tall"
			// case 3:
			//   return "photo-big-square"
			// case 4:
			//   return "photo-long"
			// case 5:
			//   return "photo-small-square"
			// case 6:
			//   return "photo-tiny"
			// case 7:
			//   return "photo-medium"
			// case 8:
			//   return "photo-large-tall"
			// case 9:
			//   return "photo-panorama"

			// case 10:
			//   return "photo-small-tall"
			// case 11:
			//   return "photo-large-wide"
			// case 12:
			//   return "photo-medium-square"
			// case 13:
			//   return "photo-large-long"
			// case 14:
			//   return "photo-extra-wide"
			// case 15:
			//   return "photo-extra-tall"
			// case 16:
			//   return "photo-small-long"
			// case 17:
			//   return "photo-medium-tall"
			// // case 18:
			// //   return "photo-extra-large"
			// case 19:
			//   return "photo-extra-small"
			// case 20:
			//   return "photo-style-21"
			// case 21:
			//   return "photo-style-22"
			// case 22:
			//   return "photo-style-23"
			// case 23:
			//   return "photo-style-24"
			// case 24:
			//   return "photo-style-25"
			// case 25:
			//   return "photo-style-26"
			// case 26:
			//   return "photo-style-27"
			// case 27:
			//   return "photo-style-28"
			// case 28:
			//   return "photo-style-29"
			// case 29:
			//   return "photo-style-30"
			default:
				return "photo-medium"
		}
	}

	return (
		<div className="picture-container">
			{images.map((image, index) => (
				<div
					key={index}
					className={`photo ${determinePhotoClass(index)}`}
					onClick={() => handleImageClick(image)}
				>
					<img src={image.src} alt={image.alt} />
				</div>
			))}

			{/* Modal pour afficher l'image sélectionnée
			{selectedImage && (
				<div className="modal" onClick={() => setSelectedImage(null)}>
					<img className="modal-content" src={selectedImage.src} alt={selectedImage.alt} />
				</div>
			)} */}
		</div>
	)
}

export default Picture
