import React from "react"
import "./PrivacyPolicy.css" // Assurez-vous que ce fichier CSS est créé
import "./../../Styles.css"
import logo from "./../../assets/logo5.png"
function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1 style={{ fontFamily: "Brush Script MT" }}>
        Politique de confidentialite
      </h1>
      <h2>Politique de confidentialité</h2>
      <p className="last-updated">Dernière mise à jour : 02/12/2023</p>
      <section>
        {/* Le contenu de votre politique de confidentialité ici */}
        <p>
          Bienvenue sur le site web de Maluko, entreprise en conseils et
          accompagnement dans vos projets de personnalisation de textiles tels
          que les t-shirts, sweats, polos, joggings, sacs, et plus encore. Chez
          Maluko, nous accordons une priorité absolue à la protection de vos
          données personnelles. La présente politique de confidentialité décrit
          en détail les types d'informations que nous collectons, comment nous
          les utilisons, et les options dont vous disposez concernant vos
          données personnelles lors de l'utilisation de notre site.
        </p>
        <p>
          En utilisant notre site web, vous acceptez les termes de cette
          politique de confidentialité.
        </p>
        {/* Ajoutez le reste du contenu ici */}
      </section>
      <h2>Collecte et Utilisation des Informations :</h2>
      <p className="last-updated">Dernière mise à jour : 02/12/2023</p>
      <section>
        {/* Le contenu de votre politique de confidentialité ici */}
        <p>
          Nous pouvons collecter différentes informations lorsque vous visitez
          notre site ou passez une commande pour personnaliser des textiles. Ces
          informations peuvent inclure, sans s'y limiter : votre nom, votre
          prénom, numéro de téléphone, adresse e-mail, adresse de livraison,
          informations de paiement, préférences de conception, etc. ainsi que
          des données non personnelles telles que des cookies, des journaux de
          serveur et d'autres technologies de suivi.
        </p>
        <p>
          <strong> 1. Informations Fournies Directement :</strong> Lorsque vous
          utilisez notre site web pour personnaliser des textiles, passer des
          commandes ou vous inscrire à notre newsletter, nous pouvons vous
          demander de fournir des informations personnelles telles que votre
          nom, votre prénom, votre adresse, votre numéro de téléphone et des
          détails de paiement. Ces informations sont nécessaires pour traiter
          vos commandes et vous fournir nos services.
        </p>
        <p>
          <strong> 2. Informations Collectées Automatiquement : </strong> Nous
          collectons automatiquement certaines informations lorsque vous accédez
          à notre site web. Ces informations peuvent inclure votre adresse IP,
          le type de navigateur que vous utilisez, les pages que vous consultez,
          la durée de votre visite et d'autres données de navigation. Nous
          utilisons ces informations pour améliorer l'expérience de nos
          utilisateurs, analyser les tendances du site et personnaliser le
          contenu en fonction de vos intérêts.
        </p>
        <p>
          <strong> 3. Utilisation des Informations : </strong> Nous utilisons
          les informations collectées pour diverses raisons, notamment :
        </p>
        <li>
          Traiter vos commandes, personnaliser les textiles et assurer la
          livraison des produits.
        </li>
        <li>
          Communiquer avec vous concernant vos commandes, les mises à jour des
          produits, les offres spéciales et les promotions.
        </li>
        <li>
          Améliorer nos services, notre site web et nos produits en fonction de
          vos retours et préférences.
        </li>
        <li>
          Protéger nos intérêts commerciaux légitimes, tels que la prévention de
          la fraude ou l'application de nos conditions d'utilisation. La liste
          ci-dessus est non exhaustive.
        </li>
        <p>
          <strong> 4. Partage d'Informations </strong> Nous ne vendons pas, ne
          louons pas et ne partageons pas vos informations personnelles avec des
          tiers, sauf dans les circonstances suivantes :
        </p>
        <li>
          Lorsque cela est nécessaire pour fournir les services demandés, tels
          que le traitement des paiements ou la livraison des commandes.
        </li>
        <li>
          Si cela est exigé par la loi ou dans le cadre d'une enquête
          officielle.
        </li>
        <p></p>
        <h2>Protection des Données :</h2>
        <li>
          Nous prenons des mesures appropriées pour sécuriser vos informations
          personnelles contre toute perte, utilisation abusive, accès non
          autorisé, divulgation, altération ou destruction. Nous conservons vos
          informations aussi longtemps que nécessaire pour atteindre les
          objectifs pour lesquels elles ont été collectées, à moins qu'une
          période de conservation plus longue ne soit requise ou permise par la
          loi.
        </li>
        <li>
          <strong>Mises à Jour et Suivi : </strong> Nous surveillons
          régulièrement nos systèmes pour détecter et prévenir les failles de
          sécurité. Nous effectuons des mises à jour techniques et révisions de
          nos politiques de sécurité pour rester conformes aux meilleures
          pratiques de protection des données.
        </li>
        <li>
          <strong>Communications sécurisées : </strong>
          Nous utilisons des protocoles sécurisés pour toutes les
          communications, y compris les transactions en ligne, afin de protéger
          vos informations sensibles lors de leur transmission sur Internet.
        </li>
        <li>
          <strong>Rapports de Violation de Données : </strong>
          En cas de violation de données mettant en danger vos informations
          personnelles, nous vous informerons dans les meilleurs délais
          conformément aux lois applicables sur la protection des données.
        </li>
        <p></p>
        <h2>Cookies et Technologies Similaires :</h2>
        <p>
          Notre site web utilise des cookies et des technologies similaires pour
          améliorer votre expérience en ligne. Ces outils collectent des
          informations telles que votre adresse IP, les pages visitées, le temps
          passé sur chaque page, etc., afin d'analyser les tendances,
          administrer le site, et personnaliser le contenu en fonction de vos
          intérêts. Vous pouvez configurer votre navigateur pour refuser tous
          les cookies ou être alerté lorsque des cookies sont envoyés. Veuillez
          noter que cela pourrait limiter certaines fonctionnalités de notre
          site.
        </p>
        <p></p>
        <h2>Vos Choix et Droits :</h2>
        <p>
          Vous avez le droit de demander à modifier vos données personnelles, de
          demander leur suppression ou de limiter leur utilisation. Vous pouvez
          également vous opposer au traitement de vos informations personnelles
          à des fins de marketing direct.
        </p>
        <p></p>
        <h2>Modifications de la Politique de Confidentialité :</h2>
        <p>
          Nous nous réservons le droit de mettre à jour cette politique de
          confidentialité à tout moment. Les modifications seront publiées sur
          cette page avec une date de révision mise à jour.
        </p>
        <h2>Contactez-Nous :</h2>
        <p>
          Si vous avez des questions concernant notre politique de
          confidentialité ou si vous souhaitez exercer vos droits sur vos
          données personnelles, veuillez nous contacter à l’adresse mail
          suivante <a href="mailto:contact@maluko.fr">contact@maluko.fr</a>
        </p>
        <h1 style={{ fontFamily: "Brush Script MT" }}>
          Maluko vous remercie pour votre confiance.
        </h1>
        {/* Ajoutez le reste du contenu ici */}
      </section>
      <img src={logo} alt="Maluko T-Shirts" className="logo" />
    </div>
  )
}

export default PrivacyPolicy
