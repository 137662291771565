import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navbar from "./components/Navbar"
import Accueil from "./pages/Accueil"
import QuiSommesNous from "./pages/Quisommesnous"
import Galerie from "./pages/Galerie"
import ContactezNous from "./pages/Contacteznous"
import SAV from "./pages/SAV"
import Stores from "./pages/Stores"
import "./App.css"
import PrivacyPolicy from "./components/Footer/PrivacyPolicy"
import Footer from "./components/Footer/Footer"

function App() {
	return (
		<Router>
			<div className="App">
				{/* Créer des divs pour chaque image de fond */}
				<div className="background-image background-image-1"></div>
				<div className="background-image background-image-2"></div>
				<div className="background-image background-image-3"></div>
				<div className="background-image background-image-4"></div>
				<div className="background-image background-image-5"></div>
				<div className="background-image background-image-6"></div>
				<div className="background-image background-image-7"></div>
				<div className="background-image background-image-8"></div>
				<Navbar />
				<Routes>
					<Route path="/" element={<Accueil />} />
					<Route path="/quiSommesNous" element={<QuiSommesNous />} />
					<Route path="/galerie" element={<Galerie />} />
					<Route path="/contacteznous" element={<ContactezNous />} /> <Route path="/SAV" element={<SAV />} />
					<Route path="/Stores" element={<Stores />} />
					<Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
					{/* Other routes */}
				</Routes>
			</div>
			<Footer />
		</Router>
	)
}

export default App
